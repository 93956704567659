import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";

import buildingImageUrl from "../images/Building.jpg";

const Item = styled.li`
  line-height: 1.5;
`;
const Toolbar = styled.div`
  @media(max-width: 700px){
    flex-direction: column !important;
  }
  display: flex;
  flex-direction: row;
`;
const Corner = styled.div`
 width: '50%';
 @media(max-width: 700px){
    width: 100% !important;
  }
`;
const Image = styled.img`
  width: 50%;
  float: left;
  height: 60vh;
  object-fit: cover;
  @media(max-width: 700px){
    width: 100% !important;
  }
`;

export default () => (
  <Layout>
    <Toolbar>
      <Corner>
        <h1>Juristische Personen / KMU</h1>
        Für Einzelfirmen und juristische Personen bieten wir folgende Versicherungen und Vorsorge an:
        <ul>
          <Item>Betriebshaftpflicht</Item>
          <Item>Berufshaftpflicht</Item>
          <Item>Sachversicherungen</Item>
          <Item>Betriebsrechtsschutz</Item>
          <Item>Verkehrsrechtsschutz</Item>
          <Item>Unfall</Item>
          <Item>Krankheit</Item>
          <Item>Todesfall</Item>
          <Item>Pensionskasse</Item>
          <Item>3. Säule</Item>
          <Item>Transport</Item>
          <Item>Assistance</Item>
          <Item>Mietkaution</Item>
        </ul>
      </Corner>
      <Image src={buildingImageUrl} alt="Zürich Stadt" />
    </Toolbar>
  </Layout>
);
